<template>
  <div class="container">
    <div class="login">
      <div class="login-img">
        <el-image :src="require('../assets/logo3.png')"></el-image>
      </div>
      <div class="login-form">
        <el-form status-icon ref="loginForm" :model="loginForm" :rules="rules">
          <el-form-item
            class="logininput"
            label="账号"
            label-width="50px"
            prop="account"
          >
            <el-input
              type="text"
              flatsholder="输入您的账号"
              v-model="loginForm.account"
              prefix-icon="el-icon-user"
              autocomplete="off"
            ></el-input>
          </el-form-item>
          <el-form-item
            class="logininput"
            label="密码"
            label-width="50px"
            prop="password"
          >
            <el-input
              type="password"
              flatsholder="输入您的密码"
              v-model="loginForm.password"
              prefix-icon="el-icon-lock"
              autocomplete="off"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-button
              size="medium"
              type="primary"
              class="loginButton"
              @click="login('loginForm')"
              >登录</el-button
            >
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "../axios.js";
import { setCookie } from "../cookie.js";
export default {
  data() {
    var checkAccount = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("账号不能为空！"));
      } else {
        callback();
      }
    };

    var checkPassword = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("密码不能为空！"));
      } else {
        callback();
      }
    };

    return {
      loginForm: {
        account: "",
        password: "",
      },
      rules: {
        account: [{ validator: checkAccount, trigger: "blur" }],
        password: [{ validator: checkPassword, trigger: "blur" }],
      },
    };
  },
  methods: {
    login(formname) {
      const that = this;
      this.$refs[formname].validate((vaild) => {
        if (vaild) {
          axios.post("login", that.loginForm).then((res) => {
            setCookie("nickName", res.data.nickName);
            setCookie("flats", res.data.flats);
            setCookie("account", that.loginForm.account);
            console.log(res);
            that.$router.push({ path: "/home" });
            // 使用vue效果在页面展示结果
            that.$message({
              type: "success",
              message: "恭喜登录成功",
              customClass: "zZindex",
            });
          });
        } else {
          this.$message({
            type: "error",
            message: "登录失败，账号、密码不能为空！",
            customClass: "zZindex",
          });
        }
      });
    },
  },
};
</script>

<style scoped>
* {
  padding: 0;
  margin: 0;
}
.container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  background-color: rgb(109, 210, 250);
}
.container .logininput {
  padding-bottom: 22px;
}

.login {
  background-color: #fff;
  display: flex;
  align-items: center;
  /* justify-content: center; */
  width: 500px;
  height: 400px;
  flex-direction: column;
  border-radius: 10px;
  border: 1px #b3c0d1 solid;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
}
/* .login-img{
    width: 519*2/3px;
    height: 119*2/3px;
    margin-top: 20px;
    margin-bottom: 20px;
} */

.login-img {
  width: 300px;
  height: 90px;
  margin-top: 55px;
  margin-bottom: 10px;
  padding-left: 5px;
}

.loginButton {
  margin: 0px;
  padding: 0px;
  width: 100%;
  margin-top: 15px;
}

.zZindex {
  z-index: 3300 !important;
}
</style>
