
import axios from '../axios.js'
import { ref,reactive } from 'vue'
import { getCookie } from '../cookie.js'
import { ElMessage,ElForm } from 'element-plus'
import { Search } from '@element-plus/icons'
export default{
  setup(){
    const dialogTableVisible = ref(false)
    const gridData = ref([])
    var enddate = new Date()
    var startdate = new Date()
    startdate.setTime(startdate.getTime() - 3600*1000*24*30)
    const defaultTime = ref([startdate,enddate])
    const shortcuts = [
      {
        text: 'Last week',
        value: () => {
          const end = new Date()
          const start = new Date()
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
          return [start, end]
        },
      },
      {
        text: 'Last month',
        value: () => {
          const end = new Date()
          const start = new Date()
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
          return [start, end]
        },
      },
      {
        text: 'Last 3 months',
        value: () => {
          const end = new Date()
          const start = new Date()
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
          return [start, end]
        },
      }
    ]

    type FormInstance = InstanceType<typeof ElForm>
    const searchFormRef = ref<FormInstance>()


    const validateDate = (rule: any, value: any, callback: any) => {
      if (value === '') {
        callback(new Error('日期不能为空'))
      }else{
        callback()
      }
    }
    const validateAccountNumber = (rule: any, value: any, callback: any) => {
      if (value === '') {
        callback(new Error('户号不能为空'))
      } else {
        callback()
      }
    }
    
    const searchForm = reactive({
      date: '',
      accountnumber: '',
    })

    const rules = reactive({
      date: [{ validator: validateDate, trigger: 'blur' }],
      accountnumber: [{ validator: validateAccountNumber, trigger: 'blur' }],
    })

    const submit = (formEl: FormInstance | undefined) => {
      if (!formEl) return
      formEl.validate((valid) => {
        if (valid) {
          let date = searchForm.date
          let startdate = date[0]
          let enddate = date[1]
          let accountnumber = searchForm.accountnumber
          let place = getCookie('place')
          let account = getCookie('account')
          axios.post('search',{startdate,enddate,accountnumber,place,account}).then(res =>{
            // 使用vue效果在页面展示结果
            // ElMessage.success(res.data.message) 
            ElMessage({
              message:res.data.message,
              type:'success',
              customClass:'zZindex'
            })
            dialogTableVisible.value = true;
            gridData.value = res.data.data
          })
        }
        else{
          ElMessage.error('日期、户号不能为空！')
        }
      })
    }

    return{
      dialogTableVisible,
      gridData,
      defaultTime,
      shortcuts,
      startdate,enddate,
      rules,
      submit,
      searchFormRef,
      searchForm
    }
  },
  components:{
    Search
  }
}

