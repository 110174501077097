import axios from 'axios'
// import Vue from 'vue'
import { ElMessage } from 'element-plus'

axios.defaults.baseURL = 'https://cloud.zjbaixiang.cn/admin/api'

// 携带 cookie，对目前的项目没有什么作用，因为我们是 token 鉴权
axios.defaults.withCredentials = true
// 请求头，headers 信息
axios.defaults.headers['X-Requested-With'] = 'XMLHttpRequest'
// axios.defaults.headers['token'] = localGet('token') || ''
// 默认 post 请求，使用 application/json 形式
axios.defaults.headers.post['Content-Type'] = 'application/json'


// 拦截器，当信息错误的时候以message的形式通知错误
axios.interceptors.response.use(res =>{
    return res
},err =>{
    ElMessage.error(err.response.data.message)
    return Promise.reject(err)
})

export default axios