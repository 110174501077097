<template>
  <div class="common-layout">
    <el-container>
      <el-aside width="auto">
        <WebAsideNav :id="activeid"></WebAsideNav>
      </el-aside>
      <!-- 主体内容 -->
      <el-container
        class="main_container"
        :style="{ height: fullHeight + 'px' }"
      >
        <el-header style="text-align: right; font-size: 12px">
          <WebHeader></WebHeader>
        </el-header>
        <el-main>
          <WebRealtime />
        </el-main>
        <el-footer><WebFoot></WebFoot></el-footer>
      </el-container>
    </el-container>
  </div>
</template>

<script>
import WebFoot from "../components/WebFoot.vue";
import WebAsideNav from "../components/WebAsideNav.vue";
import WebHeader from "../components/WebHeader.vue";
import WebRealtime from "../components/WebRealtime.vue";
import { ref } from "vue";
export default {
  components: { WebFoot, WebAsideNav, WebHeader, WebRealtime },
  setup() {
    const fullHeight = ref();
    const activeid = ref("/realtime");

    const setHeight = () => {
      fullHeight.value = window.innerHeight;
    };

    return {
      fullHeight,
      activeid,
      setHeight,
    };
  },
  created() {
    this.setHeight();
  },
  mounted() {
    const _this = this;
    window.onresize = () => {
      return _this.setHeight();
    };
  },
  unmounted() {
    window.onresize = null;
  },
};
</script>

<style scoped>
.main_container {
  min-width: 900px;
}

.el-header {
  background-color: #b3c0d1;
  color: var(--el-text-color-primary);
  text-align: center;
  line-height: 60px;
}

.el-main {
  background-color: #e9eef3;
  color: var(--el-text-color-primary);
  text-align: center;
  line-height: 160px;
}

.el-footer {
  background-color: #b3c0d1;
  color: var(--el-text-color-primary);
  text-align: center;
  line-height: 60px;
}
</style>
