<template>
  <div>
    <span>智能一体化平台欢迎你的使用</span>
    <!-- <span>版权所有：<a class="bxweb" :href="bxweb" target="_blank">xxxxxxx有限公司</a></span>
    <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
    <span>备案号：<a class="bxweb" :href="beianweb" target="_blank">XICP备XXXXXXX号</a></span> -->
  </div>
</template>

<script>
export default {
  name: "WebFoot",
  data() {
    return {
      beianweb: "http://www.beian.gov.cn/portal/registerSystemInfo",
      bxweb: "",
    };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.bxweb {
  text-decoration: none;
}
</style>
