<template>
  <div>
    <!-- <el-badge :value="0" :max="99" class="mail-item" @click="openmail">
      <el-icon class="mail" :size='30'><message /></el-icon>
    </el-badge>
    <el-drawer v-model="drawer" title="站内邮箱" :with-header="true">
      <div class="demo-collapse">
        <el-collapse v-model="activeName" accordion>
          <el-collapse-item class="mail-col-item" title="已读信箱" name="1">
            <div>
             <el-empty :image-size="20" description="无邮件"></el-empty>
            </div>
          </el-collapse-item>
          <el-collapse-item class="mail-col-item" title="未读信箱" name="2">
            <div>
              <el-empty :image-size="40" description="无邮件"></el-empty>
            </div>
          </el-collapse-item>
        </el-collapse>
      </div>
    </el-drawer> -->
    <el-dropdown trigger="hover" @command="handleCommand">
      <span class="el-dropdown-link">
        <el-avatar :src="avatarurl" style="margin-top: 10px"></el-avatar>
      </span>
      <template #dropdown>
        <el-dropdown-menu>
          <el-dropdown-item disabled="true" v-text="id"></el-dropdown-item>
          <!-- <el-dropdown-item command="setting" divided='true' align='center'>
            <el-icon><setting /></el-icon>设置
          </el-dropdown-item> -->
          <el-dropdown-item
            command="exit"
            divided="true"
            align="center"
            @click="loginout"
          >
            <el-icon><TurnOff /></el-icon>退出
          </el-dropdown-item>
        </el-dropdown-menu>
      </template>
    </el-dropdown>
  </div>
</template>

<script>
import { TurnOff } from "@element-plus/icons";
import { defineComponent } from "@vue/runtime-core";
import { ElMessage } from "element-plus";
import { useRouter } from "vue-router";
import { delCookie, getCookie } from "../cookie.js";
// import { ref } from "vue"

export default defineComponent({
  data() {
    return {
      id: getCookie("nickName"),
      avatarurl:
        "https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png",
    };
  },
  setup() {
    let router = useRouter();
    // const activeName = ref('0')
    // const drawer = ref(false)
    const loginout = () => {
      console.log("now exiting!");
      delCookie("nickName");
      delCookie("account");
      delCookie("place");
      ElMessage.success("成功退出");
      router.push({ path: "/" });
    };
    // const openmail = () =>{
    //   drawer.value = true
    // }

    return {
      loginout,
      // openmail,
      // drawer,
      // activeName
    };
  },
  components: {
    TurnOff,
  },
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.mail {
  width: 1em;
  height: 1em;
  margin-right: 10px;
  margin-bottom: 4px;
}
.mail-item {
  margin-top: 10px;
  margin-right: 20px;
}
.el-drawer__header > :first-child {
  text-align: left !important;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
  font-weight: bolder;
  font-size: 2em;
}
.mail-col-item {
  padding-left: 20px;
}
</style>
