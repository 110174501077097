import { createRouter, createWebHashHistory } from "vue-router";
import CloudHome from "../views/CloudHome.vue";
import CloudDevice from "../views/CloudDevice.vue";
import CloudElectricity from "../views/CloudElectricity.vue";
import CloudBindInfo from "../views/CloudBindInfo.vue";
import CloudLogin from "../views/CloudLogin.vue";
import CloudLog from "../views/CloudLog.vue";
import CloudEnvir from "../views/CloudEnvir.vue";
import CloudData from "../views/CloudData.vue";
import CloudRealtime from "../views/CloudRealtime.vue";

const routes = [
  {
    path: "/home",
    name: "CloudHome",
    component: CloudHome,
  },
  {
    path: "/log",
    name: "CloudLog",
    component: CloudLog,
  },
  {
    path: "/device",
    name: "CloudDevice",
    component: CloudDevice,
  },
  {
    path: "/envir",
    name: "CloudEnvir",
    component: CloudEnvir,
  },
  {
    path: "/bindinfo",
    name: "CloudBindInfo",
    component: CloudBindInfo,
  },
  {
    path: "/electricity",
    name: "CloudElectricity",
    component: CloudElectricity,
  },
  {
    path: "/",
    name: "CloudLogin",
    component: CloudLogin,
  },
  {
    path: "/data",
    name: "CloudData",
    component: CloudData,
  },
  {
    path: "/realtime",
    name: "CloudRealtime",
    component: CloudRealtime,
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export default router;
