
export function setCookie(name,value){
    const date = new Date();
    date.setTime(date.getTime() + 24*60*60*1000*7);
    window.document.cookie = name+"="+value+";expires="+date.toGMTString();
}

export function getCookie(name){
    const v = window.document.cookie.match('(^|;) ?' + name + '=([^;]*)(;|$)');
    return v ? v[2] : null;
}

export function delCookie(name){
    const date = new Date();
    date.setTime(date.getTime() - 1);
    const value = getCookie(name);
    if (value != null){
        document.cookie = name+'='+value+";expires="+date.toGMTString();
    }
}