import { createApp } from "vue";
import App from "./App.vue";
import installElementPlus from "./plugins/element";
import router from "./router";
import * as Icons from "@element-plus/icons";
import * as echarts from "echarts";

const app = createApp(App).use(router);
app.config.globalProperties.$echarts = echarts;
installElementPlus(app);
Object.keys(Icons).forEach((key) => {
  app.component(key, Icons[key as keyof typeof Icons]);
});
app.mount("#app");
